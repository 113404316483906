<template>
  <div class="page-admin-index large-page">
    <v-row>
      <v-col
        v-for="(section, index) in sections"
        :key="index"
        cols="12"
        lg="6"
      >
        <v-card
          flat
          class="section-container d-flex flex-column min-height-100 box-shadow-soft"
        >
          <v-card-title class="section-title secondary py-1">
            {{ section.title }}
          </v-card-title>

          <v-card-text class="py-3 py-md-4 py-lg-5 flex-grow-1 d-flex flex-column">
            <v-row class="flex-grow-1">
              <v-col
                v-for="route in section.routes"
                :key="route.to"
                cols="6"
                sm="4"
                md="3"
              >
                <router-link
                  class="d-flex flex-column align-start justify-space-between box-shadow-soft-hover section-card pa-2 pa-md-3"
                  :to="{ name: route.to }"
                >
                  <v-icon
                    x-large
                    class="text--text"
                  >
                    {{ route.icon }}
                  </v-icon>

                  <span class="text--text font-weight-bold">
                    {{ route.text }}
                  </span>
                </router-link>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AdminIndex",

  data: () => ({
    sections: [
      {
        title: "User Manager",
        routes: [
          {
            text: "View Users",
            icon: "manage_accounts",
            to: "Admin/Users/Index"
          },
          {
            text: "Add User",
            icon: "person_add",
            to: "Admin/Users/Create"
          },
          {
            text: "User Prices",
            icon: "attach_money",
            to: "Admin/Users/Rates"
          },
          {
            text: "Credit Usage",
            icon: "paid",
            to: "Admin/Users/Usage"
          }
        ]
      },
      {
        title: "Influencer Data Manager",
        routes: [
          {
            text: "Add / Edit Data",
            icon: "playlist_add",
            to: "Admin/Influencers/Form"
          },
          {
            text: "Manage KPI",
            icon: "person_add",
            to: "Admin/Influencers/KPI"
          },
          {
            text: "Manage Pricing",
            icon: "forward_to_inbox",
            to: "Admin/Influencers/Rates"
          }
        ]
      },
      {
        title: "Contacts Manager",
        routes: [
          {
            text: "Received Messages",
            icon: "inbox",
            to: "Admin/Contacts/Index"
          },
          {
            text: "Send Message",
            icon: "forward_to_inbox",
            to: "Admin/Contacts/Notify"
          },
        ]
      },
    ]
  })
}
</script>

<style lang="stylus" scoped>
.section-container
  border-radius 0

  .section-card
    height 100%
    background-color var(--v-accent)
    border 2px solid #999999
    text-decoration none
</style>
